import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Checkbox, Input, Stack, Text, useBreakpoints } from '@procsea/design-system'

import { LocaleParams } from 'src/types'

import { CountrySelector } from '../../shared/fields'
import { KLARYS_TERMS_OF_USE_URL, StyledRouterLink } from '../common'
import {
  CustomizableButton,
  CustomizableLink,
  InlinedInputsContainer,
  TermsOfUseLabelContainer,
} from '../styles'
import { useBuyerSignupForm } from './useBuyerSignupForm'

interface FormProps {
  companyUuid: string | null
  customCtaColor?: string | null
  showSignInLink: boolean
}

const Form = ({ companyUuid, customCtaColor, showSignInLink }: FormProps) => {
  const { isMobile } = useBreakpoints()
  const {
    errors,
    fields,
    formState: { isSubmitting },
    onSubmit,
    setValue,
  } = useBuyerSignupForm({ companyUuid: companyUuid ?? undefined })

  const { locale } = useParams<LocaleParams>()
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)

  const shouldDisplayCompanyFields = !companyUuid

  const SideBySideInputsContainer = isMobile ? React.Fragment : InlinedInputsContainer

  return (
    <form noValidate onSubmit={onSubmit}>
      <Stack spacing="large">
        <SideBySideInputsContainer>
          <Input
            fluid
            data-e2e="buyer-signup-first_name-input"
            disabled={isSubmitting}
            errorMessage={errors.firstName?.message}
            label={gettext('First name')}
            onChange={({ value }) => {
              setValue('firstName', value)
            }}
            value={fields.firstName}
          />

          <Input
            fluid
            data-e2e="buyer-signup-last_name-input"
            disabled={isSubmitting}
            errorMessage={errors.lastName?.message}
            label={gettext('Last name')}
            onChange={({ value }) => {
              setValue('lastName', value)
            }}
            value={fields.lastName}
          />
        </SideBySideInputsContainer>

        <Input
          fluid
          data-e2e="buyer-signup-email-input"
          disabled={isSubmitting}
          errorMessage={errors.email?.message}
          label={gettext('Business email')}
          onChange={({ value }) => {
            setValue('email', value)
          }}
          type="email"
          value={fields.email}
        />

        <Input
          fluid
          data-e2e="buyer-signup-password-input"
          disabled={isSubmitting}
          errorMessage={errors.password?.message}
          icon={isPasswordVisible ? 'eye' : 'eye-slash'}
          label={gettext('Create your password')}
          onChange={({ value }) => {
            setValue('password', value)
          }}
          onIconClick={() => setIsPasswordVisible(oldValue => !oldValue)}
          type={isPasswordVisible ? 'text' : 'password'}
          value={fields.password}
        />

        <Input
          fluid
          data-e2e="buyer-signup-phone_fixed-input"
          disabled={isSubmitting}
          errorMessage={errors.phoneFixed?.message}
          label={gettext('Phone number')}
          onChange={({ value }) => {
            setValue('phoneFixed', value)
          }}
          value={fields.phoneFixed}
        />
        {shouldDisplayCompanyFields && (
          <>
            <Input
              fluid
              data-e2e="buyer-signup-company_name-input"
              disabled={isSubmitting}
              errorMessage={errors.companyName?.message}
              label={gettext('Company name')}
              onChange={({ value }) => {
                setValue('companyName', value)
              }}
              value={fields.companyName}
            />

            <Input
              fluid
              data-e2e="buyer-signup-address-input"
              disabled={isSubmitting}
              errorMessage={errors.address?.message}
              label={gettext('Address')}
              onChange={({ value }) => {
                setValue('address', value)
              }}
              value={fields.address}
            />

            <SideBySideInputsContainer>
              <Input
                fluid
                data-e2e="buyer-signup-zipcode-input"
                disabled={isSubmitting}
                errorMessage={errors.zipCode?.message}
                label={gettext('Zip code')}
                onChange={({ value }) => {
                  setValue('zipCode', value)
                }}
                value={fields.zipCode}
              />

              <Input
                fluid
                data-e2e="buyer-signup-locality-input"
                disabled={isSubmitting}
                errorMessage={errors.locality?.message}
                label={gettext('Locality')}
                onChange={({ value }) => {
                  setValue('locality', value)
                }}
                value={fields.locality}
              />
            </SideBySideInputsContainer>

            <CountrySelector
              data-e2e="buyer-signup-country-input"
              disabled={isSubmitting}
              errorMessage={errors.country?.message}
              onChange={({ value }) => {
                setValue('country', value as string)
              }}
              value={fields.country}
            />
          </>
        )}

        <Checkbox
          content={
            <TermsOfUseLabelContainer>
              {`${gettext('I agree to the')}`}
              &nbsp;
              <CustomizableLink color={customCtaColor} href={KLARYS_TERMS_OF_USE_URL[locale]}>
                {gettext('general terms of use')}
              </CustomizableLink>
            </TermsOfUseLabelContainer>
          }
          data-e2e="buyer-signup-terms-checkbox"
          disabled={isSubmitting}
          name="terms"
          onChange={({ value }) => {
            setValue('terms', value)
          }}
          value={fields.terms}
        />

        {!!errors.terms?.message && <Text variant="danger">{errors.terms.message}</Text>}

        <CustomizableButton
          backgroundColor={customCtaColor}
          data-e2e="buyer-signup-submit-button"
          disabled={isSubmitting}
          type="submit"
          variant={customCtaColor ? 'neutral' : 'primary'}
        >
          {isSubmitting ? gettext('Signing up') : gettext('Sign up')}
        </CustomizableButton>

        {showSignInLink && (
          <Text variant="neutral">
            {gettext('Already using Klarys?')}&nbsp;
            <StyledRouterLink data-e2e="buyer-signup-login-link" to={`/${locale}/accounts/login`}>
              {gettext('Sign in here')}
            </StyledRouterLink>
          </Text>
        )}
      </Stack>
    </form>
  )
}

export default Form
